/* eslint-disable import/prefer-default-export */

/* eslint-disable no-useless-concat */
// eslint-disable-next-line import/prefer-default-export
export const SidekickLpSuccess = {
  title: "success stories",
  subTitle: "Our merchant",
};

export const SidekickLPHero = {
  title: "Portable order & payment tech for food trucks and more",
  subtext:
    "Boost efficiency, increase sales, and keep guests coming back with SpotOn Sidekick, the all-in-one handheld POS built for mobile kitchens.",
  heroImg: "Sidekick-lp.png",
  imageClassName: "fixed-height-600",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/demo-sidekick",
  },
  fatTitle: "Sidekick",
  fatTitleClass: "font-large",
};

export const SidekickLPWhiteGlove = {
  mainTitle: "Personalized support that moves with you",
  title: "Expert support for mobile kitchens",
  content:
    "You deserve the same white-glove service that big restaurants expect, whether you’re running a food truck, taco stand, food cart, concession stand, or other on-the-go food businesses.",
  contentList: [
    "Premium in-person hardware installation",
    "Face-to-face staff training from SpotOn specialists",
    "Ongoing virtual training for staff and management",
  ],
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/demo-sidekick",
  },
};

export const SidekickLPLargeFeatures = {
  sectionTitle: "The tech you need to keep your business rolling",
  featureBlocks: [
    {
      blockTitle: "A faster way to take orders and payments",
      blockImg: "Sidekick-lp-1.png",
      blockSubTitle: "",
      blockList: [
        "All-in-one handheld POS built for mobile kitchens",
        "In-person and online orders funneled straight to your kitchen",
        "Custom menus for on-site and online ordering",
        "Integrated online ordering ",
        "Customer email capture with integrated marketing",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/demo-sidekick",
      },
    },
    {
      blockTitle: "Shorten the lines and get more orders",
      blockSubTitle: "",
      blockImg: "Sidekick-lp-2.png",
      blockList: [
        "Online ordering through your website and Facebook page",
        "QR code order & pay to shorten your lines",
        "Optional no-hassle local delivery",
        "Real-time updates to menu, hours, and other options",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/demo-sidekick",
      },
    },
    {
      blockTitle: "Integrated reporting, marketing, and more",
      blockSubTitle: "",
      blockImg: "Sidekick-lp-3.png",
      blockList: [
        "90+ easy-to-use & cloud-based reports",
        "Reporting app to monitor data anywhere, anytime",
        "Mobile dashboard for email marketing and Facebook campaigns",
        "Real-time review monitoring",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/demo-sidekick",
      },
    },
  ],
};

export const SidekickLPTestimonials = {
  title: "What other resaturant owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes. I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const SidekickLPCTA = {
  title: "Book your consultation.",
  ctaImg: "sidekick-cta.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/demo-sidekick",
  },
};

export const SidekickLPTemplate = [
  {
    content: "Reservations & waitlisting through your website",
    desc: "Keep your tables full and reduce walk-aways by letting your guests book directly from you.",
    img: "Online_reservation.png",
  },
  {
    content: "Sidekick with Google listing",
    desc: "Attract more guests by enabling them to Sidekick a table or add themselves to your guest list right from a web search.",
    img: "Google_listings.png",
  },
  {
    content: "Host application for iPad",
    desc: "Boost efficiency of your front-of-house by easily managing reservations, waitlists, and walk-ins.",
    img: "Host_application.png",
  },
  {
    content: "Server & table management",
    desc: "Streamline operations, reduce labor costs, and turn tables faster with powerful front-of-house tools.",
    img: "Service_management.png",
  },
  {
    content: "Web portal access",
    desc: "Easily manage your front-of-house, wherever you are, including across multiple location.",
    img: "Online_reservation.png",
  },
  {
    content: "Customer analytics",
    desc: "Know who your guests are and where they’re coming from, so you can drive repeat visits.",
    img: "reporting.png",
  },
];
