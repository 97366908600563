import React from "react";
import loadable from "@loadable/component";
import ogImage from "../../images/global_assets/og-image.png";
import LpLayout from "../../components/Layout/lp-layout";
import SEO from "../../components/seo";
import Hero from "../../components/Hero/hero";
// import SuccessStories from '../../components/SuccessStories/SuccessStories';
import TestmonialReviews from "../../components/TestimonialReviews/TestmonialReviews";
import {
  SidekickLPHero,
  SidekickLPWhiteGlove,
  SidekickLPLargeFeatures,
  SidekickLPCTA,
  SidekickCsSlides,
  SidekickLpSuccess,
  SidekickLPTestimonials,
} from "../../data/landing/sidekick-lp";
// import Badges from "../../components/ConversionPath/Badges";
// import LargeFeatures from "../../components/LargeFeatures/LargeFeatures";
// import WhiteGlove from "../../components/WhiteGlove/WhiteGlove";
// import LargeCta from "../../components/CTAs/LargeCta";
// import PartnerLogos from "../../components/PartnerLogos/PartnerLogos";

const Badges = loadable(() => import("../../components/ConversionPath/Badges"));
const LargeFeatures = loadable(() =>
  import("../../components/LargeFeatures/LargeFeatures")
);
const WhiteGlove = loadable(() =>
  import("../../components/WhiteGlove/WhiteGlove")
);
const LargeCta = loadable(() => import("../../components/CTAs/LargeCta"));
const PartnerLogos = loadable(() =>
  import("../../components/PartnerLogos/PartnerLogos")
);

const whiteGloveImport = "sidekick-whiteglove.png";

const SideKick = () => {
  return (
    <LpLayout phoneNumber="+18882246506" phoneText="(888) 224-6506">
      <SEO
        title="SpotOn Sidekick| Food truck POS | Mobile POS"
        description="SpotOn Sidekick makes it easier than ever for on-the-go food businesses like food trucks to take orders, print tickets, process payments, and stay online just about anywhere."
        image={`https://spoton.com/${ogImage}`}
        robotsData="noindex, nofollow"
      />

      <Hero sectionData={SidekickLPHero} circleBg={false} />
      <section style={{ marginTop: "40px" }}>
        <Badges />
      </section>

      <section style={{ marginTop: "60px" }}>
        {/* <SuccessStories sectionData={SidekickLpSuccess} slides={SidekickCsSlides} /> */}
        <TestmonialReviews sectionData={SidekickLPTestimonials} />
      </section>

      <PartnerLogos />

      <LargeFeatures
        sectionData={SidekickLPLargeFeatures}
        style={{ marginTop: "60px" }}
      />

      <WhiteGlove
        sectionData={SidekickLPWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />

      <LargeCta
        sectionData={SidekickLPCTA}
        imgClassName="ctaNew"
        style={{ paddingRight: 0 }}
      />
    </LpLayout>
  );
};

export default SideKick;
